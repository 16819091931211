var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.DataLoading)?_c('loading-animation'):_c('div',{staticClass:"ml-3 mr-3"},[_c('div',{staticClass:"fl-te-c mb-3"},[_c('h4',[_vm._v("Request Details")])]),(_vm.data)?_c('div',{staticClass:"card bs-4 p-0 pb-3"},[_c('div',{staticClass:"fl-te-c mb-3 bb-1 p-3"},[_c('h5',[_vm._v("Order Details")]),_c('div',{staticClass:"btn-group"},[_c('btn',{staticClass:"btn-basic-b",attrs:{"size":"sm","text":"Back"},on:{"click":function($event){return _vm.$router.push('/purchase/order/request-list/')}}})],1)]),_c('div',{staticClass:"row pl-3 pt-3 pr-5"},[_c('div',{staticClass:"col-2 label"},[_vm._v("Request ID")]),_c('div',{staticClass:"col-2 p-1 b-1 text-muted"},[_vm._v(_vm._s(_vm.data.request_id))]),_c('div',{staticClass:"col-2"}),_c('div',{staticClass:"col-2 label"},[_vm._v("Requested by")]),_c('div',{staticClass:"col-2 p-1 b-1 text-muted"},[_vm._v(_vm._s(_vm.data.created_by))]),_c('div',{staticClass:"col-2"})]),_c('div',{staticClass:"row pl-3 pt-3 pr-5"},[_c('div',{staticClass:"col-2 label"},[_vm._v("Status")]),_c('div',{staticClass:"col-2 p-1 b-1 text-muted"},[_vm._v(_vm._s(_vm.data.status))]),_c('div',{staticClass:"col-2"}),_c('div',{staticClass:"col-2 label"},[_vm._v("Date")]),_c('div',{staticClass:"col-2 p-1 b-1 text-muted"},[_vm._v(_vm._s(_vm.data.created_date))]),_c('div',{staticClass:"col-2"})]),(_vm.data.items.length!==0)?_c('custom-simple-table',{ref:"table",staticClass:"table-borderless mt-4 mb-0",attrs:{"data":_vm.data.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"unit_price",fn:function(ref){
var rowData = ref.rowData;
return [((rowData.status!=='Accepted' && rowData.status!=='Rejected'))?_c('validated-input',{attrs:{"placeholder":"Price","type":"number","name":"Price","rules":{required: false}},model:{value:(rowData.unit_price),callback:function ($$v) {_vm.$set(rowData, "unit_price", $$v)},expression:"rowData.unit_price"}}):_c('span',[_vm._v(_vm._s(rowData.unit_price))])]}},{key:"total",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.quantity)?_c('span',[_vm._v(_vm._s(rowData.unit_price * rowData.quantity))]):_c('span',[_vm._v("0")])]}},{key:"vendor",fn:function(ref){
var rowData = ref.rowData;
return [((rowData.status!=='Accepted' && rowData.status!=='Rejected'))?_c('validated-ajax-vue-select',{staticClass:"mb-0",staticStyle:{"min-width":"12rem"},attrs:{"size":"md","url":_vm.vendorOptions,"placeholder":"Vendor","name":"Vendor","rules":{required: false}},model:{value:(rowData.vendor),callback:function ($$v) {_vm.$set(rowData, "vendor", $$v)},expression:"rowData.vendor"}}):_c('span',[_vm._v(_vm._s(rowData.vendor_name))])]}},{key:"remarks",fn:function(ref){
var rowData = ref.rowData;
return [((rowData.status!=='Accepted' && rowData.status!=='Rejected'))?_c('validated-input',{attrs:{"placeholder":"Remarks","name":"Remarks","rules":{required: false}},model:{value:(rowData.remarks),callback:function ($$v) {_vm.$set(rowData, "remarks", $$v)},expression:"rowData.remarks"}}):_c('span',[_vm._v(_vm._s(rowData.remarks))])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group"},[((rowData.status!=='Accepted' && rowData.status!=='Rejected'))?_c('btn',{staticClass:"btn-basic-b",attrs:{"size":"xs","loading":_vm.loading && rowData.id===_vm.r_id,"loading-text":"Accepting...","text":"Accept"},on:{"click":function($event){return _vm.acceptClicked(rowData)}}}):_vm._e(),((rowData.status!=='Accepted' && rowData.status!=='Rejected'))?_c('btn',{staticClass:"ml-1 btn-basic-b",attrs:{"size":"xs","loading":_vm.loading1 && rowData.id===_vm.r_id,"loading-text":"Rejecting...","text":"Reject"},on:{"click":function($event){return _vm.rejectClicked(rowData)}}}):_vm._e(),(rowData.status==='Accepted')?_c('btn',{staticClass:"ml-1",attrs:{"size":"xs","color":"success","text":rowData.status}}):_vm._e(),(rowData.status==='Rejected')?_c('btn',{staticClass:"ml-1",attrs:{"size":"xs","color":"danger","text":rowData.status}}):_vm._e()],1)]}}],null,false,1150773899)}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }