<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="ml-3 mr-3">
        <div class="fl-te-c mb-3">
            <h4>Request Details</h4>
        </div>
        <div v-if="data" class="card bs-4 p-0 pb-3">
            <div class="fl-te-c mb-3 bb-1 p-3">
                <h5>Order Details</h5>
                <div class="btn-group">
                    <btn size="sm" class="btn-basic-b" @click="$router.push('/purchase/order/request-list/')"
                         text="Back"></btn>
                </div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Request ID</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.request_id }}</div>
                <div class="col-2"></div>
                <div class="col-2 label">Requested by</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.created_by }}</div>
                <div class="col-2"></div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.status }}</div>
                <div class="col-2"></div>
                <div class="col-2 label">Date</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.created_date }}</div>
                <div class="col-2"></div>
            </div>
            <custom-simple-table class="table-borderless mt-4 mb-0" v-if="data.items.length!==0" :data="data.items"
                                 :fields="fields" ref="table">
                <template #unit_price="{rowData}">
                    <validated-input v-if="(rowData.status!=='Accepted' && rowData.status!=='Rejected')"
                                     placeholder="Price" type="number" name="Price" v-model="rowData.unit_price"
                                     :rules="{required: false}"/>
                    <span v-else>{{ rowData.unit_price }}</span>
                </template>
                <template #total="{rowData}">
                    <span v-if="rowData.quantity">{{ rowData.unit_price * rowData.quantity }}</span>
                    <span v-else>0</span>
                </template>
                <template #vendor="{rowData}">
                    <validated-ajax-vue-select v-if="(rowData.status!=='Accepted' && rowData.status!=='Rejected')"
                                               style="min-width: 12rem;" size="md" class="mb-0" :url="vendorOptions"
                                               placeholder="Vendor"
                                               name="Vendor" :rules="{required: false}"
                                               v-model="rowData.vendor">
                    </validated-ajax-vue-select>
                    <span v-else>{{ rowData.vendor_name }}</span>
                </template>
                <template #remarks="{rowData}">
                    <validated-input v-if="(rowData.status!=='Accepted' && rowData.status!=='Rejected')"
                                     placeholder="Remarks" name="Remarks" v-model="rowData.remarks"
                                     :rules="{required: false}"/>
                    <span v-else>{{ rowData.remarks }}</span>
                </template>
                <template #actions="{rowData}">
                    <div class="btn-group">
                        <btn size="xs" v-if="(rowData.status!=='Accepted' && rowData.status!=='Rejected')"
                             class="btn-basic-b" :loading="loading && rowData.id===r_id" loading-text="Accepting..."
                             @click="acceptClicked(rowData)" text="Accept"></btn>
                        <btn size="xs" v-if="(rowData.status!=='Accepted' && rowData.status!=='Rejected')"
                             class="ml-1 btn-basic-b" :loading="loading1 && rowData.id===r_id"
                             loading-text="Rejecting..." @click="rejectClicked(rowData)" text="Reject"></btn>
                        <btn size="xs" v-if="rowData.status==='Accepted'"
                             class="ml-1" color="success" :text="rowData.status"></btn>
                        <btn size="xs" v-if="rowData.status==='Rejected'"
                             class="ml-1" color="danger" :text="rowData.status"></btn>
                    </div>
                </template>
            </custom-simple-table>
        </div>
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '../../../../data/urls';

export default {
    name : 'DetailsPage',
    data () {
        return {
            DataLoading   : false,
            loading       : false,
            loading1      : false,
            data          : '',
            r_id          : '',
            vendorOptions : urls.purchaseAdmin.request.vendorOptions,
            id            : this.$route.params.id,
            fields        : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Product'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name  : '__slot:unit_price',
                    title : 'Price'
                },
                {
                    name  : '__slot:total',
                    title : 'Total'
                },
                {
                    name  : '__slot:vendor',
                    title : 'Vendor'
                },
                {
                    name  : '__slot:remarks',
                    title : 'Remarks'
                },
                {
                    name  : '__slot:actions',
                    title : 'Actions'
                }

            ]
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.purchaseAdmin.request.details, { id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.data = json.data;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        },
        async acceptClicked (item) {
            this.r_id = '';
            this.r_id = item.id;
            const dict = {};
            dict.purchase_request = this.id;
            dict.order_remarks = item.remarks;
            dict.purchase_vendor = item.vendor;
            dict.unit_price = item.unit_price;
            dict.quantity = item.quantity;
            dict.total = item.quantity * item.unit_price;
            dict.product = item.product;
            dict.item = item.id;
            dict.id = this.id;
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.purchaseAdmin.request.accept, dict);
            const json = response.data;
            if (json.error === false) {
                that.data.items = json.request_order_list.data.items;
                that.$notify('Request added to purchase order list', 'Success', {
                    type : 'success'
                });
                that.loading = false;
            } else if (json.errors) {
                that.$notify('Please fill all the fields before submit', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            } else {
                that.$notify('Unable to accept the request.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        },
        async rejectClicked (rowData) {
            this.r_id = '';
            this.r_id = rowData.id;
            const dict = {};
            dict.id = this.id;
            dict.item = rowData.id;
            dict.order_remarks = rowData.remarks;
            dict.id = this.id;
            const that = this;
            that.loading1 = true;
            const response = await axios.form(urls.purchaseAdmin.request.reject, dict);
            const json = response.data;
            if (json.error === false) {
                that.data.items = json.request_order_list.data.items;
                that.$notify('Request rejected', 'Success', {
                    type : 'success'
                });
                that.loading1 = false;
            } else {
                that.$notify('Unable to reject the request.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.loading1 = false;
            }
        }
    }
};
</script>

<style scoped>
.b-1 {
    border-radius: 4px;
}

.label {
    font-weight: 500;
}
</style>
